import FetchAPI from 'mycs/shared/utilities/FetchAPI/FetchAPI';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';
import useSWR from 'swr';

export const SampleBoxUUID = 'SampleBox';

export const SampleBoxType = 'sample';

export enum AddonSKU {
  AssemblyService = '701.101.02',
  BaseboardCut = '701.102.00',
}

export enum DiscountType {
  QuantityDiscount = 'QuantityDiscount',
  CategoryDiscount = 'CategoryDiscount',
  VolumeDiscount = 'VolumeDiscount',
}

export enum LineItemType {
  Shelf = 'shelf',
}

export enum TransactionStatus {
  Pending = 'pending',
  Paid = 'paid',
  Failed = 'failed',
}

export enum PaymentResult {
  Success = 'success',
  Refused = 'refused',
  Error = 'error',
  Pending = 'pending',
}

export enum PaymentMethod {
  BankTransferIBAN = 'bankTransferIBAN',
  Card = 'card',
  PayPal = 'paypal',
  SEPADirectDebit = 'sepaDirectDebit',
  EPS = 'eps',
  TWINT = 'twint',
  Installment = 'installment',
  Deferred = 'deferred',
  IDeal = 'ideal',
  Giropay = 'giropay',
  Sofort = 'sofort',
  GooglePay = 'googlePay',
  ApplePay = 'applePay',
  Invoice = 'invoice',
  PayByBank = 'paybybank',
  KlarnaPayLater = 'klarna',
  KlarnaPayOverTime = 'klarna_account',
  KlarnaPayNow = 'klarna_paynow',
}

export enum PaymentProvider {
  Adyen = 'adyen',
  Braintree = 'braintree',
  Alma = 'alma',
  AlmaDeferred = 'almaDeferred',
  Viva = 'viva',
  Mycs = 'mycs',
}

export enum InstallmentNumber {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

export enum APIError {
  VATValidation500 = '500 Internal Server Error Failed to look up VAT Number',
}

export const AlmaEligibleOrderTotalMax = 5000;
export const AlmaEligibleOrderTotalMin = 50;

interface PostOrderPayloadAddress {
  firstName: string;
  lastName: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  zip: string;
  meta?: {
    [key: string]: any;
  };
}

export interface PostOrderPayload extends PostCartPayload {
  email: string;
  shippingAddress: PostOrderPayloadAddress;
  billingAddress?: PostOrderPayloadAddress | null;
  estimate?: boolean;
  note?: string;
}

export interface Order extends Cart {
  billingAddress: {
    address1: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    // createdAt: string;
    // deletedAt: null;
    firstName: string;
    id: string;
    lastName: string;
    meta?: {
      elevator?: boolean;
      extension?: string;
      floor?: string;
      phone?: string;
      validated?: boolean;
    };
    zip: string;
  };
  // billingAddressID: string;
  email: string;
  estimate: boolean;
  test: boolean;
  // fulfillmentState: string;
  id: string;
  // invoiceNumber?: number;
  // ip: string;
  meta?: {
    termsConsentGiven?: boolean;
    newsletterConsentGiven?: boolean;
    shippingGroups?: ShippingPayload[];
    fulfillment?: {
      status: string;
      delayReason: string;
      events: {
        status: string;
        eventDate: string;
        estimatedDate: string;
      }[];
      shippingAddress: {
        name: string;
        address1: string;
        address2: string;
        address3: string;
        city: string;
        zip: string;
        country: string;
      };
      billingAddress: {
        name: string;
        address1: string;
        address2: string;
        address3: string;
        city: string;
        zip: string;
        country: string;
      };
    };
  };
  notes?: {
    text: string;
    // userID: string;
    // createdAt: string;
    // updatesAt: string;
  }[];
  // paymentState: string;
  shippingAddress: {
    address1: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    // createdAt: string;
    // deletedAt: null;
    firstName: string;
    id: string;
    lastName: string;
    meta?: {
      elevator?: boolean;
      extension?: string;
      floor?: string;
      phone?: string;
      validated?: boolean;
    };
    zip: string;
  };
  // shippingAddressID: string;
  // state: string;
  transactions?: {
    amount: number;
    createdAt: string;
    currency: string;
    id: string;
    invoiceNumber: number;
    meta: { returnURL: string };
    orderID: string;
    paymentMethod: PaymentMethod;
    paymentProvider: string;
    providerMeta: any;
    providerReference: string;
    status: TransactionStatus;
    type: string;
  }[];
  updatedAt: string;
  createdAt: string;
  // user?: {};
  // userID?: string;
}
type PostOrderResponse = Order;
type GetOrderResponse = Order;

interface BaseboardCutMeta {
  depth: number;
  height: number;
  titleLocalized: string;
}

export type LineItemAddon =
  | {
    sku: AddonSKU.AssemblyService;
    price: number;
    meta: {
      localizedTitle: string;
    };
  }
  | {
    sku: AddonSKU.BaseboardCut;
    price: number;
    meta: BaseboardCutMeta;
  }
  | {
    // Material Sample
    sku: string;
    meta: {
      localizedTitle: string;
    };
  };

export interface PostCartPayloadLineItemAddon {
  sku: string;
  meta?: { [key: string]: any };
}

export interface PostCartPayloadLineItem {
  uuid: string;
  quantity: number;
  meta?: {
    [key: string]: any;
  };
  addons?: PostCartPayloadLineItemAddon[];
}

interface PostCartPayload {
  countryCode: string;
  lineItems: PostCartPayloadLineItem[];
  couponCode?: string;
  vatNumber?: string;
  sessionID?: string;
  test?: boolean;
  meta?: {
    [key: string]: any;
  };
}

export type Coupon = {
  code: string;
  campaignName: string;
  transactionID: string;
  transactionTotalAmount: number;
  grantedDiscount: number;
  valid: boolean;
  redeemed: boolean;
  rewards: any;
};

export interface LineItem {
  itemID: string;
  addonPrice: number;
  addonItems?: LineItemAddon[];
  discountItems?: {
    type: string;
    percentage: number;
    fixed: number;
  }[];
  calculation: {
    discount: number;
    netTotal: number;
    subtotal: number;
    taxes: number;
    total: number;
  };
  description: string;
  id: number;
  meta: {
    title?: string;
    type?: string;
    imageURL?: string;
    productDetailsPageURL?: string;
    dimensions?: {
      width: number;
      height: number;
      length: number;
    };

    baseboardCut?: {
      depth: number;
      height: number;
    };
    availableAddons?: {
      sku: string;
      price: number;
    }[];
    availableDiscounts?: {
      type: string;
      percentage?: number;
      quantityPercentage?: {
        [key: string]: number;
      };
    }[];
    specs?: {
      [key: string]: number | string | number[];
    };
    structure: {
      [key: string]: any;
    };
    colors?: {
      [key: string]: number | string;
    };
  };
  path: string;
  price: number;
  quantity: number;
  uuid: string;
  title: string;
  type: string;
  vat: number;
}

export interface AdyenRequestLineItem {
  quantity: number;
  taxPercentage: number;
  description: string;
  id: string;
  amountIncludingTax: number;
  productUrl: string;
  imageUrl?: string;  
}

export interface ShippingPayload {
  uuids: string[];
  shippingMethodID: number;
  shippingMethodName: string;
  totalDays: number;
  daysUntilDispatch: number;
}

export interface Cart {
  // All the values are in int.
  id: string;
  countryCode: string;
  currency: string;
  vatNumber: string;
  lineItemDiscount: number;
  couponDiscount: number;
  couponCode?: string;
  vat: number;
  coupon?: Coupon;
  netTotal: number;
  shippingCost: number;
  shippingCostBeforeDiscount: number;
  subtotal: number;
  taxes: number;
  total: number;
  lineItems: null | LineItem[];
  meta?: {
    shipping?: ShippingPayload;
    shippingGroups?: ShippingPayload[];
  };
}

type PostCartResponse = Cart;

type QueryPSPInitParamsPayload =
  | {
    countryCode: string;
    provider: PaymentProvider.Adyen;
    providerMeta: {
      amount: {
        value: number;
        currency: string;
      };
      shopperLocale: string;
    };
  }
  | {
    countryCode: string;
    provider: PaymentProvider.Braintree;
  }
  | {
    countryCode: string;
    provider: PaymentProvider.Alma;
    providerMeta:
    | {
      deferredPayment: true;
      orderTotal: number;
      daysUntilDispatch: number;
      lineItemTypes: string[];
    }
    | {
      deferredPayment: false;
      orderTotal: number;
    };
  }
  | {
    countryCode: string;
    provider: PaymentProvider.Viva;
    providerMeta: {
      orderTotal: number;
    };
  };

// The response is passed directly to the drop-in,
// so the shape could be undefined.
type QueryPSPInitParamsResponse = any;

type PostPaymentPayload =
  | {
    provider: PaymentProvider.Adyen;
    countryCode: string;
    amount: number;
    currency: string;
    providerMeta: {
      paymentMethod: any; // Adyen drop-in output
      origin: string;
      shopperLocale: string;
      browserInfo: {
        language: string;
        colorDepth: number;
        screenHeight: number;
        screenWidth: number;
        timeZoneOffset: number;
        javaEnabled: boolean;
      };
      shopperReference?: string;
      lineItems?: AdyenRequestLineItem[] | null;
    };
  }
  | {
    provider: PaymentProvider.Braintree;
    countryCode: string;
    amount: number;
    currency: string;
    providerMeta: {
      paymentMethodID: string;
    };
  }
  | {
    provider: PaymentProvider.Alma;
    countryCode: string;
    amount: number;
    currency: string;
    providerMeta: {
      deferredPayment: boolean;
      installmentNumber: number;
    };
  }
  | {
    provider: PaymentProvider.Viva;
    countryCode: string;
    amount: number;
    currency: string;
  }
  | {
    provider: PaymentProvider.Mycs;
    countryCode: string;
    amount: number;
    currency: string;
    providerMeta: {
      paymentMethod: string;
    };
  };

interface PostPaymentResponse {
  id: string;
  invoiceNumber: number;
  paymentProvider: string;
  paymentMethod: PaymentMethod;
  providerMeta?: {
    // Adyen
    additionalData?: any;
    action?: any; // passed directly to the drop-in.
    // Alma
    redirectURL?: string;
  };
}

interface GetVatValidationResponse {
  address: string;
  company: string;
  country: string;
  valid: true;
}

type PostPaymentConfirmationPayload =
  // Adyen
  {
    details: any;
  };

type PostPaymentConfirmationResponse = PostPaymentResponse;

interface GetStoreConfig {
  paymentProviders: PaymentProvider[];
}

const apiURL = UrlProviderService.getCheckoutAPIURL();

export function postCart(payload: PostCartPayload): Promise<PostCartResponse> {
  return FetchAPI.post(`${apiURL}/carts`, payload);
}

export function postOrder(
  payload: PostOrderPayload
): Promise<PostOrderResponse> {
  return FetchAPI.post(`${apiURL}/orders`, payload);
}

export function getOrder(orderID: string): Promise<GetOrderResponse> {
  return FetchAPI.get(`${apiURL}/orders/${orderID}`);
}

export function queryPSPInitParams(
  payload: QueryPSPInitParamsPayload
): Promise<QueryPSPInitParamsResponse> {
  return FetchAPI.post(`${apiURL}/payments/init`, payload);
}

export function postPayment(
  orderID: string,
  payload: PostPaymentPayload
): Promise<PostPaymentResponse> {
  return FetchAPI.post(
    `${apiURL}/orders/${orderID}/payments`,
    payload,
    // We need to override the "Accept: 'application/json'" header.
    // The service endpoint passes the default value of the header to Adyen.
    { headers: {} }
  );
}

export function postPaymentConfirmation(
  paymentID: string,
  payload: PostPaymentConfirmationPayload
): Promise<PostPaymentConfirmationResponse> {
  return FetchAPI.post(`${apiURL}/payments/${paymentID}/confirm`, payload);
}

export function getVatValid(vat: string): Promise<GetVatValidationResponse> {
  return FetchAPI.get(`${apiURL}/vatnumbers/${vat}`);
}

/**
 * Use store config SWR hook.
 */
export function useStoreConfig(storeCode: string) {
  const { data: storeConfig } = useSWR<GetStoreConfig>(
    `${apiURL}/store-config/${storeCode}`,
    FetchAPI.get
  );

  return { storeConfig };
}
